import React from "react"
import MainLayout from "../../layouts/MainLayout"
import Seo from "../../components/seo"

export default function Terms() {
  return (
    <MainLayout>
      <Seo
        title="Tailwind CSS Terms and Conditions - Component Library WindUI"
        ogtitle="Tailwind CSS Terms and Conditions - Component Library WindUI"
        description="WindUI Terms and Conditions"
        ogdescription="WindUI Terms and Conditions"
        url="terms/"
        author="Wind UI"
        keywords="Terms and Conditions"
        robots="index, follow"
        revisit_after="7 days"
      />
      <section className="py-16 lg:py-20 ">
        <div className="container">
          {/* <!-- Component content --> */}
          <div className="grid items-center grid-cols-4 gap-6 lg:grid-cols-12">
            <div className="justify-center col-span-4 border-b border-b-wuiSlate-200 lg:col-span-8 lg:col-start-3">
              <h1 className="pb-6 text-4xl font-medium text-wuiSlate-900">
                Terms & Conditions
              </h1>
              <p className="pb-6 text-lg">
                Read about the terms and conditions for WindUI.
              </p>
            </div>
            <div className="justify-center col-span-4 lg:col-span-8 lg:col-start-3">
              <p className="pb-6 text-md">
                These Terms of Use constitute a legally binding agreement made
                between you, whether personally or on behalf of an entity
                ("you") and Wind UI ("Company", "we", "us", or "our"),
                concerning your access to and use of the{" "}
                <a href="/" className="text-emerald-500">
                  https://wind-ui
                </a>{" "}
                website as well as any other media form, media channel, mobile
                website or mobile application related, linked, or otherwise
                connected thereto (collectively, the "Site"). The Site provides
                an online marketplace for the following goods, products, and/or
                services: website themes and templates (the "Marketplace
                Offerings"). In order to help make the Site a secure environment
                for the purchase and sale of Marketplace Offerings, all users
                are required to accept and comply with these Terms of Use. You
                agree that by accessing the Site and/or the Marketplace
                Offerings, you have read, understood, and agree to be bound by
                all of these Terms of Use.
              </p>
              <p className="pb-6 font-medium text-md">
                If you do not agree with all of these terms of use, then you are
                expressly prohibited from using the site and/or the marketplace
                offerings and you must discontinue use immediately.
              </p>
              <p className="pb-6 text-md">
                Supplemental terms and conditions or documents that may be
                posted on the Site from time to time are hereby expressly
                incorporated herein by reference. We reserve the right, in our
                sole discretion, to make changes or modifications to these Terms
                of Use at any time and for any reason. We will alert you about
                any changes by updating the "Last updated" date of these Terms
                of Use, and you waive any right to receive specific notice of
                each such change. It is your responsibility to periodically
                review these Terms of Use to stay informed of updates. You will
                be subject to, and will be deemed to have been made aware of and
                to have accepted, the changes in any revised Terms of Use by
                your continued use of the Site after the date such revised Terms
                of Use are posted.
              </p>
              <p className="pb-6 text-md">
                The information provided on the Site is not intended for
                distribution to or use by any person or entity in any
                jurisdiction or country where such distribution or use would be
                contrary to law or regulation or which would subject us to any
                registration requirement within such jurisdiction or country.
                Accordingly, those persons who choose to access the Site from
                other locations do so on their own initiative and are solely
                responsible for compliance with local laws, if and to the extent
                local laws are applicable.
              </p>
              <h2 className="pb-6 text-xl font-medium text-wuiSlate-800">
                Intellectual Property Rights
              </h2>
              <p className="pb-6 text-md">
                Unless otherwise indicated, the Site and the Marketplace
                Offerings are our proprietary property and all source code,
                databases, functionality, software, website designs, audio,
                video, text, photographs, and graphics on the Site
                (collectively, the "Content") and the trademarks, service marks,
                and logos contained therein (the "Marks") are owned or
                controlled by us or licensed to us, and are protected by
                copyright and trademark laws and various other intellectual
                property rights and unfair competition laws of the United
                States, international copyright laws, and international
                conventions. The Content and the Marks are provided on the Site
                "AS IS" for your information and personal use only. Except as
                expressly provided in these Terms of Use, no part of the Site or
                the Marketplace Offerings and no Content or Marks may be copied,
                reproduced, aggregated, republished, uploaded, posted, publicly
                displayed, encoded, translated, transmitted, distributed, sold,
                licensed, or otherwise exploited for any commercial purpose
                whatsoever, without our express prior written permission.
              </p>
              <p className="pb-6 text-md">
                Provided that you are eligible to use the Site, you are granted
                a limited license to access and use the Site and to download or
                print a copy of any portion of the Content to which you have
                properly gained access solely for your personal, non-commercial
                use. We reserve all rights not expressly granted to you in and
                to the Site, the Content and the Marks.
              </p>
              <h2 className="pb-6 text-xl font-medium text-wuiSlate-800">
                User Representations
              </h2>
              <p className="pb-6 text-md">
                By using the Site or the Marketplace Offerings, you represent
                and warrant that: (1) you have the legal capacity and you agree
                to comply with these Terms of Use; (2) you are not a minor in
                the jurisdiction in which you reside; (3) you will not access
                the Site or the Marketplace Offerings through automated or
                non-human means, whether through a bot, script or otherwise; (4)
                you will not use the Site for any illegal or unauthorized
                purpose; and (5) your use of the Site or the Marketplace
                Offerings will not violate any applicable law or regulation.
              </p>
              <p className="pb-6 text-md">
                If you provide any information that is untrue, inaccurate, not
                current, or incomplete, we have the right to suspend or
                terminate your use and refuse any and all future use of the Site
                (or any portion thereof).
              </p>
              <p className="pb-6 text-md">
                You may not use the Site or the Marketplace Offerings for any
                illegal or unauthorized purpose nor may you, in the use of
                Marketplace Offerings, violate any laws. Among unauthorized
                Marketplace Offerings are the following: intoxicants of any
                sort; illegal drugs or other illegal products; alcoholic
                beverages; games of chance; and pornography or graphic adult
                content, images, or other adult products. Postings of any
                unauthorized products or content may result in immediate
                termination of your account and a lifetime ban from use of the
                Site.
              </p>
              <p className="pb-6 text-md">
                We are a service provider and make no representations as to the
                safety, effectiveness, adequacy, accuracy, availability, prices,
                ratings, reviews, or legality of any of the information
                contained on the Site or the Marketplace Offerings displayed or
                offered through the Site. You understand and agree that the
                content of the Site does not contain or constitute
                representations to be reasonably relied upon, and you agree to
                hold us harmless from any errors, omissions, or
                misrepresentations contained within the Site’s ontent. We do not
                endorse or recommend any Marketplace Offerings and the Site is
                provided for informational and advertising purposes only.
              </p>
              <h2 className="pb-6 text-xl font-medium text-wuiSlate-800">
                Marketplace Offerings
              </h2>
              <p className="pb-6 text-md">
                We make every effort to display as accurately as possible the
                colors, features, specifications, and details of the Marketplace
                Offerings available on the Site. However, we do not guarantee
                that the colors, features, specifications, and details of the
                Marketplace Offerings will be accurate, complete, reliable,
                current, or free of other errors, and your electronic display
                may not accurately reflect the actual colors and details of the
                products.
              </p>
              <p className="pb-6 text-md">
                All descriptions or pricing of the Marketplace Offerings are
                subject to change at any time without notice, at our sole
                discretion. We reserve the right to discontinue any Marketplace
                Offerings at any time for any reason. We do not warrant that the
                quality of any of the Marketplace Offerings purchased by you
                will meet your expectations or that any errors in the Site will
                be corrected.
              </p>
              <h2 className="pb-6 text-xl font-medium text-wuiSlate-800">
                Prohibited Activities
              </h2>
              <p className="pb-6 text-md">
                You may not access or use the Site for any purpose other than
                that for which we make the Site available. The Site may not be
                used in connection with any commercial endeavors except those
                that are specifically endorsed or approved by us.
              </p>
              <h2 className="pb-6 text-xl font-medium text-wuiSlate-800">
                Contribution License
              </h2>
              <p className="pb-6 text-md">
                You and the Site agree that we may access, store, process, and
                use any information and personal data that you provide following
                the terms of the Privacy Policy and your choices (including
                settings). By submitting suggestions or other feedback regarding
                the Site, you agree that we can use and share such feedback for
                any purpose without compensation to you.
              </p>
              <h2 className="pb-6 text-xl font-medium text-wuiSlate-800">
                Site Management
              </h2>
              <p className="pb-6 text-md">
                We reserve the right, but not the obligation, to: (1) monitor
                the Site for violations of these Terms of Use; (2) take
                appropriate legal action against anyone who, in our sole
                discretion, violates the law or these Terms of Use, including
                without limitation, reporting such user to law enforcement
                authorities; (3) in our sole discretion and without limitation,
                refuse, restrict access to, limit the availability of, or
                disable (to the extent technologically feasible) any of your
                Contributions or any portion thereof; (4) in our sole discretion
                and without limitation, notice, or liability, to remove from the
                Site or otherwise disable all files and content that are
                excessive in size or are in any way burdensome to our systems;
                and (5) otherwise manage the Site in a manner designed to
                protect our rights and property and to facilitate the proper
                functioning of the Site and the Marketplace Offerings.
              </p>
              <h2 className="pb-6 text-xl font-medium text-wuiSlate-800">
                Term And Termination
              </h2>
              <p className="pb-6 text-md">
                These terms of use shall remain in full force and effect while
                you use the site. Without limiting any other provision of these
                terms of use, we reserve the right to, in our sole discretion
                and without notice or liability, deny access to and use of the
                site and the marketplace offerings (including blocking certain
                ip addresses), to any person for any reason or for no reason,
                including without limitation for breach of any representation,
                warranty, or covenant contained in these terms of use or of any
                applicable law or regulation. We may terminate your use or
                participation in the site and the marketplace offerings or
                delete any content or information that you posted at any time,
                without warning, in our sole discretion.
              </p>
              <h2 className="pb-6 text-xl font-medium text-wuiSlate-800">
                Modifications And Interruptions
              </h2>
              <p className="pb-6 text-md">
                We reserve the right to change, modify, or remove the contents
                of the Site at any time or for any reason at our sole discretion
                without notice. However, we have no obligation to update any
                information on our Site. We also reserve the right to modify or
                discontinue all or part of the Marketplace Offerings without
                notice at any time. We will not be liable to you or any third
                party for any modification, price change, suspension, or
                discontinuance of the Site or the Marketplace Offerings.
              </p>
              <p className="pb-6 text-md">
                We cannot guarantee the Site and the Marketplace Offerings will
                be available at all times. We may experience hardware, software,
                or other problems or need to perform maintenance related to the
                Site, resulting in interruptions, delays, or errors. We reserve
                the right to change, revise, update, suspend, discontinue, or
                otherwise modify the Site or the Marketplace Offerings at any
                time or for any reason without notice to you. You agree that we
                have no liability whatsoever for any loss, damage, or
                inconvenience caused by your inability to access or use the Site
                or the Marketplace Offerings during any downtime or
                discontinuance of the Site or the Marketplace Offerings. Nothing
                in these Terms of Use will be construed to obligate us to
                maintain and support the Site or the Marketplace Offerings or to
                supply any corrections, updates, or releases in connection
                therewith.
              </p>
              <h2 className="pb-6 text-xl font-medium text-wuiSlate-800">
                Disclaimer
              </h2>
              <p className="pb-6 text-md">
                The site and the marketplace offerings are provided on an as-is
                and as-available basis. You agree that your use of the site and
                our services will be at your sole risk. To the fullest extent
                permitted by law, we disclaim all warranties, express or
                implied, in connection with the site and the marketplace
                offerings and your use thereof, including, without limitation,
                the implied warranties of merchantability, fitness for a
                particular purpose, and non-infringement. We make no warranties
                or representations about the accuracy or completeness of the
                site’s content or the content of any websites linked to the site
                and we will assume no liability or responsibility for any (1)
                errors, mistakes, or inaccuracies of content and materials, (2)
                personal injury or property damage, of any nature whatsoever,
                resulting from your access to and use of the site, (3) any
                unauthorized access to or use of our secure servers and/or any
                and all personal information and/or financial information stored
                therein, (4) any interruption or cessation of transmission to or
                from the site or the marketplace offerings, (5) any bugs,
                viruses, trojan horses, or the like which may be transmitted to
                or through the site by any third party, and/or (6) any errors or
                omissions in any content and materials or for any loss or damage
                of any kind incurred as a result of the use of any content
                posted, transmitted, or otherwise made available via the site.
                We do not warrant, endorse, guarantee, or assume responsibility
                for any product or service advertised or offered by a third
                party through the site, any hyperlinked website, or any website
                or mobile application featured in any banner or other
                advertising, and we will not be a party to or in any way be
                responsible for monitoring any transaction between you and any
                third-party providers of products or services. As with the
                purchase of a product or service through any medium or in any
                environment, you should use your best judgment and exercise
                caution where appropriate.
              </p>
              <h2 className="pb-6 text-xl font-medium text-wuiSlate-800">
                Limitations Of Liability
              </h2>
              <p className="pb-6 text-md">
                In no event will we or our directors, employees, or agents be
                liable to you or any third party for any direct, indirect,
                consequential, exemplary, incidental, special, or punitive
                damages, including lost profit, lost revenue, loss of data, or
                other damages arising from your use of the site or the
                marketplace offerings, even if we have been advised of the
                possibility of such damages. Notwithstanding anything to the
                contrary contained herein, our liability to you for any cause
                whatsoever and regardless of the form of the action, will at all
                times be limited to the lesser of the amount paid, if any, by
                you to us. Certain us state laws and international laws do not
                allow limitations on implied warranties or the exclusion or
                limitation of certain damages. If these laws apply to you, some
                or all of the above disclaimers or limitations may not apply to
                you, and you may have additional rights.
              </p>
              <h2 className="pb-6 text-xl font-medium text-wuiSlate-800">
                Indemnification
              </h2>
              <p className="pb-6 text-md">
                You agree to defend, indemnify, and hold us harmless, including
                our subsidiaries, affiliates, and all of our respective
                officers, agents, partners, and employees, from and against any
                loss, damage, liability, claim, or demand, including reasonable
                attorneys’ fees and expenses, made by any third party due to or
                arising out of: (1) use of the Marketplace Offerings; (2) breach
                of these Terms of Use; (3) any breach of your representations
                and warranties set forth in these Terms of Use; (4) your
                violation of the rights of a third party, including but not
                limited to intellectual property rights; or (5) any overt
                harmful act toward any other user of the Site or the Marketplace
                Offerings with whom you connected via the Site. Notwithstanding
                the foregoing, we reserve the right, at your expense, to assume
                the exclusive defense and control of any matter for which you
                are required to indemnify us, and you agree to cooperate, at
                your expense, with our defense of such claims. We will use
                reasonable efforts to notify you of any such claim, action, or
                proceeding which is subject to this indemnification upon
                becoming aware of it.
              </p>
              <h2 className="pb-6 text-xl font-medium text-wuiSlate-800">
                User Data
              </h2>
              <p className="pb-6 text-md">
                We will maintain certain data that you transmit to the Site for
                the purpose of managing the performance of the Marketplace
                Offerings, as well as data relating to your use of the
                Marketplace Offerings. Although we perform regular routine
                backups of data, you are solely responsible for all data that
                you transmit or that relates to any activity you have undertaken
                using the Marketplace Offerings. You agree that we shall have no
                liability to you for any loss or corruption of any such data,
                and you hereby waive any right of action against us arising from
                any such loss or corruption of such data.
              </p>
              <h2 className="pb-6 text-xl font-medium text-wuiSlate-800">
                Electronic Communications, Transactions, And Signatures
              </h2>
              <p className="pb-6 text-md">
                Visiting the site, sending us emails, and completing online
                forms constitute electronic communications. You consent to
                receive electronic communications, and you agree that all
                agreements, notices, disclosures, and other communications we
                provide to you electronically, via email and on the site,
                satisfy any legal requirement that such communication be in
                writing. You hereby agree to the use of electronic signatures,
                contracts, orders, and other records, and to electronic delivery
                of notices, policies, and records of transactions initiated or
                completed by us or via the site. You hereby waive any rights or
                info-tip under any statutes, regulations, rules, ordinances, or
                other laws in any jurisdiction which require an original
                signature or delivery or retention of non-electronic records, or
                to payments or the granting of credits by any means other than
                electronic means.
              </p>
              <h2 className="pb-6 text-xl font-medium text-wuiSlate-800">
                Miscellaneous
              </h2>
              <p className="pb-6 text-md">
                These Terms of Use and any policies or operating rules posted by
                us on the Site or in respect to the Marketplace Offerings
                constitute the entire agreement and understanding between you
                and us. Our failure to exercise or enforce any right or
                provision of these Terms of Use shall not operate as a waiver of
                such right or provision. These Terms of Use operate to the
                fullest extent permissible by law. We may assign any or all of
                our rights and obligations to others at any time. We shall not
                be responsible or liable for any loss, damage, delay, or failure
                to act caused by any cause beyond our reasonable control. If any
                provision or part of a provision of these Terms of Use is
                determined to be unlawful, void, or unenforceable, that
                provision or part of the provision is deemed severable from
                these Terms of Use and does not affect the validity and
                enforceability of any remaining provisions. There is no joint
                venture, partnership, employment or agency relationship created
                between you and us as a result of these Terms of Use or use of
                the Marketplace Offerings. You agree that these Terms of Use
                will not be construed against us by virtue of having drafted
                them. You hereby waive any and all defenses you may have based
                on the electronic form of these Terms of Use and the lack of
                signing by the parties hereto to execute these Terms of Use.
              </p>
              <h2 className="pb-6 text-xl font-medium text-wuiSlate-800">
                Contact Us
              </h2>
              <p className="pb-12 text-md">
                In order to resolve a complaint regarding the Site or the
                Marketplace Offerings or to receive further information
                regarding use of the Site or the Marketplace Offerings, please
                contact us at{" "}
                <a
                  href="mailto:info@wind-ui.com"
                  aria-label="Contact WindUI link"
                  target="blank"
                  className="text-emerald-500"
                >
                  info@wind-ui.com
                </a>
              </p>
              <p className="text-sm font-medium">Updated at 19 Jul 2022</p>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  )
}
